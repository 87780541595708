.element-__body {
  font-family: Helvetica;
  background-color: #000000;
}

@media (max-width: 1024px) {
  .element-__body {
    
  }
}

@media (max-width: 768px) {
  .element-__body {
    
  }
}
.element-d182584a-5e08-4f88-9fe0-96ef5348584d {
  padding: 1rem;
}

@media (max-width: 1024px) {
  .element-d182584a-5e08-4f88-9fe0-96ef5348584d {
    
  }
}

@media (max-width: 768px) {
  .element-d182584a-5e08-4f88-9fe0-96ef5348584d {
    
  }
}


.element-4793dc61-d3cd-4f96-bf27-d82b5bdd8928 {
  color: #ffffff;
  font-size: 90px;
}

@media (max-width: 1024px) {
  .element-4793dc61-d3cd-4f96-bf27-d82b5bdd8928 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .element-4793dc61-d3cd-4f96-bf27-d82b5bdd8928 {
    font-size: 20px;
  }
}